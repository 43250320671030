var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-image"},[(_vm.showPreview)?_c('div',{staticStyle:{"width":"100vw","height":"100vh","z-index":"10000","position":"fixed","top":"0","left":"0","background-color":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticStyle:{"color":"white","font-size":"30px","padding":"30px 30px 15vh","text-align":"right","cursor":"pointer"},on:{"click":function($event){_vm.showPreview = false}}},[_c('span',{staticClass:"el-icon-close"})]),_c('img',{staticStyle:{"max-height":"70vh","max-width":"70vw"},attrs:{"src":_vm.previewImageUrl,"alt":"","srcset":""}})]):_vm._e(),_c('div',{staticClass:"answer-image-el-upload"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.importFileUrl,"show-file-list":false,"accept":".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF","on-success":(response, file, fileList) =>
                    _vm.handlePictureUploadSuccess(
                        response,
                        file,
                        fileList,
                        _vm.unit.content
                    ),"before-upload":_vm.beforeImageMUpload}},[(_vm.unit.content.url)?_c('div',{staticClass:"avatar-box"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.unit.content.url}}),_c('div',{staticClass:"handle-box"},[_c('i',{staticClass:"el-icon-search",on:{"click":function($event){$event.stopPropagation();return _vm.viewImage(_vm.unit.content.url)}}}),_c('i',{staticClass:"el-icon-refresh-right",attrs:{"slot":"trigger"},slot:"trigger"})])]):_c('div',{staticClass:"no-url-upload",attrs:{"slot":"trigger"},slot:"trigger"},[_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])])],1),_c('div',{staticClass:"answer-image-limit"},[_vm._v(" "+_vm._s(_vm.$t('flowComponent.uploadImageTip'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }