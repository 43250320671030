import axios from 'axios';
const OSS = window.OSS;

const ossConfig = {
    region: "oss-cn-zhangjiakou",
    //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
    accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
    accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
    // stsToken: '<Your securityToken(STS)>',
    bucket: "guoranopen-zjk",
};

let IDX = 256, HEX = [], SIZE = 256, BUFFER;
while (IDX--) HEX[IDX] = (IDX + 256).toString(16).substring(1);

function uid(len) {
    let i = 0, tmp = (len || 11);
    if (!BUFFER || ((IDX + tmp) > SIZE * 2)) {
        for (BUFFER = '', IDX = 0; i < SIZE; i++) {
            BUFFER += HEX[Math.random() * 256 | 0];
        }
    }

    return BUFFER.substring(IDX, IDX++ + tmp);
}

function dataFormat(fmt, date = new Date()) {
    const o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (const k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function pathGenerate(filename) {
    return "front-oss/" + localStorage.getItem('_mainId') + "/" + dataFormat("yyyy/MM/dd/hh/mm/") + uid(32) + "/" + filename.split("+").join('_');
}


function upload(ossConfig, data) {
    let ossClient = new OSS(ossConfig);
    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    let objectKey = pathGenerate(data.name);
    let result = ossClient.put(objectKey, data);
    console.debug(result);
    return result;
}

function spliteOldUrl(url) {
    let key = ''
    url = url.split('?')[0]
    if(url.includes('https')) {
        key = url.replace('https://guoranwisdom.oss-cn-zhangjiakou.aliyuncs.com/', '')
    } else {
        key = url.replace('http://guoranwisdom.oss-cn-zhangjiakou.aliyuncs.com/', '')
    }
    return key
}

async function uploadImageByBase64(ossConfig, blob, filename, key) {
    let ossClient = new OSS(ossConfig);
    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    let objectKey = '';
    if(key) {
        objectKey = spliteOldUrl(key)
    } else {
        objectKey = pathGenerate(new Date().getTime());
        if(filename) {
            objectKey = objectKey + filename
        }
    }
    let result = await ossClient.put(objectKey, blob);
    console.debug(result);
    return result;
}
function multipartUpload(ossConfig, data, callback, extCallback, isAudio) {

    if (process.env.VUE_APP_UPLOAD_TYPE == 'PRIVATE') {
        const formData = new FormData();
        formData.append('file', data); // 将文件添加到 FormData 中

        async function uploadPrivate() {
            let res = await axios.post('/filehandler-api/fileupload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return {
                name: res.data.data,
                url: res.data.data,
                nameType: process.env.VUE_APP_UPLOAD_TYPE,
                res: {
                    status: 200
                }
            };
        }

        return uploadPrivate();
    } else {
        // console.log(extCallback);
        let ossClient = new OSS(ossConfig);
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let name = data.name;
        if (isAudio && /.*[\u4e00-\u9fa5]+.*$/.test(name)) { // 解决小程序 ios 端无法播放中文文件名音频问题
            name = "voice_" + uid(32) + "." +name.split(".")[1];
        }
        let objectKey = pathGenerate(name);

        let res = ossClient.multipartUpload(objectKey, data, {
            progress: function (p, checkpoint) {
                // console.debug('progress callback', p, checkpoint);
                // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
                if (callback && callback instanceof Function) {
                    callback(p, checkpoint, data, extCallback);
                }
            }
        })
        return res;
    }
}

function ossFileUrl(ossConfig, path, cname) {
    if (cname == null) {
        return 'https://' + ossConfig.bucket + '.' + ossConfig.region + '.aliyuncs.com/' + path;
    } else {
        return cname + '/' + path;
    }
}

export { upload, multipartUpload, ossFileUrl, uploadImageByBase64, ossConfig }

