<template>
	<div class="skill-manage">
		<div class="outer-self">
			<div class="title-box">
				<span class="title">{{$t('botIntent.skillManage.title')}}</span>
			
			</div>
			<div class="self-skill">
				<div
				v-for="(item, index) in selfSkillList"
				:key="index"
				:class="['self-skill-cell',index+1 === selfSkillList.length ? '' : '']"
				>
				<span class="order-number">{{ index + 1 }}</span>
				<div class="skill-cell-content">
					<div class="skill-cell-content-name">
					<span v-if="!item.isEdit">{{ item.name }}</span>
					<el-input
						:ref="index+'isEdit'"
						v-if="item.isEdit"
						size="mini"
						v-model.trim="item.name"
						maxlength="15"
					></el-input>
					</div>
					<div class="skill-cell-handle">
						<em
							@click="editSkill(index)"
							v-show="RP_Visible('OPEN_SKILL_UPDATE') && item.refAttrId == null && !item.isEdit"
							class="iconfont guoran-a-18_huaban1fuben3"
						></em>
						<em
							v-if="RP_Visible('OPEN_SKILL_UPDATE') && selfSkillList.length > 1"
							@click="deleteSkill(item,index)"
							class="guoran-tongyichicun-16-09-shanchu2 iconfont"
						></em>
						<em  v-if="RP_Visible('OPEN_SKILL_UPDATE') && selfSkillList.length > 1" class="iconfont guoran-a-16-10"></em>
						<em  v-if="RP_Visible('OPEN_SKILL_UPDATE') && selfSkillList.length > 1" class="iconfont guoran-tongyichicun-16-16-paixuhover"></em>
					</div>
				</div>
				</div>
			</div>
			<div class="add-btn" v-if="RP_Visible('OPEN_SKILL_UPDATE')" @click="addSkill"> <i class="el-icon-plus"></i> {{$t('botIntent.skillManage.addKnowledgeBase')}}</div>
		</div>
		<div class="outer-auth">
			<div class="title-box">
				<div @click="isActiveType = 0" :class="['title','guan',{'activeTitle': isActiveType == 0}]">{{$t('botIntent.skillManage.officialTemplate')}}</div>
				<div v-if="botVersion != 2" @click="isActiveType = 1" :class="['title','my',{'activeTitle': isActiveType == 1}]">{{$t('botIntent.skillManage.myTemplate')}}</div>
				<div v-if="botVersion == 2" @click="versionTip" :class="['title']">{{$t('botIntent.skillManage.myTemplate')}}</div>
			</div>
			<div v-if="isActiveType == 0" class="self-skill know-store-manage">
				<div
				v-for="(item, index) in pubSkillList"
				:key="index"
				:class="['self-skill-cell',item.disabled ? 'disabled':hover,index+1 === pubSkillList.length ? '' : '']"
				>
				<span class="order-number">{{ index + 1 }}</span>
				<div class="skill-cell-content">
					<div class="skill-cell-content-name template">
						<el-tooltip class="item" effect="dark" :content="item.skillName" placement="top-start">
							<span>{{ item.skillName }}</span>
						</el-tooltip>
					</div>
					<div class="add-my-know" 	@click="addToSelf(item, 1)"> <i class="el-icon-plus"></i> {{$t('botIntent.skillManage.addMyTemplate')}}</div>
				</div>
				</div>
			</div>
			<div v-if="isActiveType == 1" class="self-skill">
				<div
				v-for="(item, index) in userTemplates"
				:key="index"
				:class="['self-skill-cell',item.disabled ? 'disabled':hover]"
				>
				<span class="order-number">{{ index + 1 }}</span>
				<div class="skill-cell-content">
					<div class="skill-cell-content-name template">
						<el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
							<span>{{ item.name }}</span>
						</el-tooltip>
					</div>
					<div class="skill-cell-handle-join">
					<div class="add-my-know" v-if="RP_Visible('OPEN_SKILL_UPDATE')"	@click="addToSelf(item, 2)"> <i class="el-icon-plus"></i> {{$t('botIntent.skillManage.addMyTemplate')}}</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Sortable from "sortablejs";
export default {
	props: ['isShowHeathly'],
	data() {
		return {
			name: "skill-manage",
			selfSkillList: [],
			pubSkillList: [],
			userTemplates: [],
			isActiveType: 0,
			botVersion: '',
			delSkillNames:[],
			oldSelfSkillList: [], // 老数据-知识库

		};
	},
	mounted() {
		this.getSkillList();
		this.botVersion = localStorage.getItem('_bot_version');
	},
	methods: {
		versionTip(){
		this.$emit("changeSkillVersionTipDialog")
		},
		/**
		 * 保存我的知识库
		 * @method saveSkillManage
		 * @param {}
		 * @return {}
		 */
		saveSkillManage() {
			let flag = true;
			this.selfSkillList.forEach((item,index) => {
				if(item.name === ''){
					flag = false;
				}
			})
			if(flag){
				this.FetchPut(this.requestUrl.skill.updateSkill, "", {
					botId: this.$route.query.id,
					skills: this.selfSkillList,
					delSkillNames:this.delSkillNames
				}).then((res) => {
					if (res.code === "0") {
						this.$message.success("保存成功！");
						this.$emit("getSkillListSkillManage",'isStart');
						let list = [];
						console.log(this.selfSkillList);
						console.log(this.oldSelfSkillList);
						this.selfSkillList.forEach((item,index) => {
							this.oldSelfSkillList.forEach((oldItem,oldIndex) => {
								if(item.id === oldItem.id){
									if(item.name !== oldItem.name){
										list.push(item);
									}
								}
							})
						})
						if(this.isShowHeathly){
							sessionStorage.setItem('differentSkill',JSON.stringify(list));
							this.$eventBus.$emit("bot-heathly-visible", {
								flag:this.isShowHeathly,
								type:"updateIntentSkill"
							});
						}
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				this.$message.error('请输入知识库名字');
			}
			
		},
		/**
		 * 修改我的知识库名称为编辑态
		 * @method addToSelf
		 * @param {Object} item 官方知识库信息
		 * @return {}
		 */
// 		          <!-- 添加了某个知识库模板（官方+企业模板）后，再添加知识库模板时，不允许被添加（机器人管理中及知识库管理中两个页面需要限制）；
// 机器人管理中，添加已有机器人时不允许添加到引用了模板的机器人中，选择时给提示“该机器人已使用了该知识库，不允许添加同一个该知识库”
// 知识库管理中，使用按钮置灰，点击提示“该机器人已使用了该知识库，不允许添加同一个该知识库” -->
		addToSelf(item, type) {
			// if(type === 1){
			// 	if(item.disabled){
			// 		this.$message.error('该机器人已使用了该知识库，不允许添加同一个知识库');
			// 	} else {
			// 		item.disabled = true;
			// 			this.selfSkillList.push({
			// 			name:item.skillName,
			// 			refApiKey: item.apiKey,
			// 			refAttrId: item.skillId,
			// 			isEdit: false
			// 		});
			// 	}
				
			// } else {
			// 	this.selfSkillList.push({
			// 		name: item.name,
			// 		refApiKey: item.apiKey,
			// 		refAttrId: item.skillId,
			// 		isEdit: false
			// 	});
			// }
			if(item.disabled){
					this.$message.error('该机器人已使用了该知识库，不允许添加同一个知识库');
				} else {
					item.disabled = true;
						this.selfSkillList.push({
						name:type === 1 ? item.skillName : item.name,
						refApiKey: item.apiKey,
						refAttrId: item.skillId,
						isEdit: false
					});
				}
		},
		/**
		 * 修改我的知识库名称为编辑态
		 * @method editSkill
		 * @param {Number} index 索引
		 * @return {}
		 */
		editSkill(index) {
			this.selfSkillList[index].isEdit = true;
		},
		/**
		 * 删除我的知识库
		 * @method deleteSkill
		 * @param {Number} index 索引
		 * @return {}
		 */
		deleteSkill(item,index) {
		if (this.selfSkillList[index].intentNum > 0) {
			// this.$confirm("当前知识库下存在意图，不能执行删除操作", "提示", {
			// 	confirmButtonText: "确定",
			// 	showCancelButton: false,
			// 	type: "warning",
			// 	})
			// .then(() => {})
			// .catch(() => {});
			this.$message.error('当前知识库下存在意图，不能执行删除操作');
		} else {
			this.selfSkillList.splice(index, 1);
			if(item.id){
				this.delSkillNames.push(item)
				console.log(this.delSkillNames);
			}
			this.pubSkillList.forEach((v,i) => {
				if(v.skillId === item.refAttrId){
					v.disabled = false;
				}
			}) 
			this.userTemplates.forEach((v,i) => {
				if(v.skillId === item.refAttrId){
					v.disabled = false;
				}
			}) 
			
		}
		},
		/**
		 * 新增我的知识库
		 * @method deleteSkill
		 * @param {}
		 * @return {}
		 */
		addSkill() {
			this.selfSkillList.push({ name: "", isEdit: true });
			
			this.$nextTick(() => {
				let ref = this.selfSkillList.length-1+'isEdit'
				this.$refs[ref][0].focus();
				document.querySelector('.self-skill').scrollTop+40;
			})
		},
		/**
		 * 根据botId获取我的知识库列表
		 * @method getSkillList
		 * @param {}
		 * @return {}
		 */
		getSkillList() {
			let botId = this.$route.query.id;

			//获取技能列表
			this.FetchGet(this.requestUrl.skill.getSkillList, {
				bid: botId,
			}).then((res) => {
				if (res.code === "0") {
					if (res.data != null) {
						res.data.forEach((item, index) => {
							item.isEdit = false;
							item.orderNum = index + 1;
						});
					}
					this.selfSkillList = res.data;
					this.oldSelfSkillList = JSON.parse(JSON.stringify(res.data));
					this.FetchGet(this.requestUrl.skill.templateSkillListAll, {}).then((res) => {
						if (res.code === "0") {
							this.pubSkillList = [];
							this.userTemplates = []
							// this.userTemplates = res.data.userTemplates;
							res.data.defaultTemplates.forEach((item,index) => {
								item.disabled = false;
								this.selfSkillList.forEach((v,i) =>{
									if(item.skillId === v.refAttrId){
										item.disabled = true;
									}
								})
								this.pubSkillList.push(item);
							})
							res.data.userTemplates.forEach((item,index) => {
								item.disabled = false;
								this.selfSkillList.forEach((v,i) =>{
									if(item.skillId === v.refAttrId){
										item.disabled = true;
									}
								})
								this.userTemplates.push(item);
							})
						}
					});
					console.log(this.pubSkillList,111111);
					this.$nextTick(() => {
						this.rowDrop();
					});
				}
			});

			
			// this.FetchGet(this.requestUrl.skill.templateSkillList, {}).then((res) => {
			//   if (res.code === "0") {
			//     this.pubSkillList = res.data;
			//   }
			// });
		},
		/**
		 * 数组元素交换位置
		 * @param {array} arr 数组
		 * @param {number} index1 添加项目的位置
		 * @param {number} index2 删除项目的位置
		 * index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
		 */
		swapArray(arr, index1, index2) {
		arr[index1] = arr.splice(index2, 1, arr[index1])[0];
		return arr;
		},
		/**
		 * 列表元素拖动
		 * @method rowDrop
		 * @param {}
		 * @return {}
		 */

		rowDrop() {
		const box = document.querySelector(".self-skill");
		const _this = this;
		new Sortable(box, {
			ghostClass: "blue-background-class",
			handle: '.iconfont',
			animation: 500,
			sort: true,
			onUpdate: function (event) {
			//修改items数据顺序
			let newIndex = event.newIndex,
				oldIndex = event.oldIndex,
				$li = box.children[newIndex],
				$oldLi = box.children[oldIndex];
			// 先删除移动的节点
			box.removeChild($li);
			// 再插入移动的节点到原有节点，还原了移动的操作
			if (newIndex > oldIndex) {
				box.insertBefore($li, $oldLi);
			} else {
				box.insertBefore($li, $oldLi.nextSibling);
			}
			// 更新items数组
			let item = _this.selfSkillList.splice(oldIndex, 1);
			_this.selfSkillList.splice(newIndex, 0, item[0]);
			// 下一个tick就会走patch更新
			},
		});
		this.selfSkillList = _this.selfSkillList;
		},
	},
	
};
</script>
<style lang="less" scoped>
	.skill-manage {
		display: flex;
		justify-content: space-around;
		.outer-self {
			width: 338px;
			max-height: 450px;
			min-height: 300px;
			background: #FBFCFD;
			border: 1px solid #E0E6F7;
			border-radius: 5px;
			padding-top: 15px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			// margin-bottom: 50px;
			.title-box {
				height: 28px;
				flex: none;
				display: flex;
				justify-content: center;
				align-items: center;
				.title {
					font-weight: 700;
					height: 28px;
					cursor: pointer;
					font-size: 14px;
					font-weight: bold;
					color: #000000;
				}
				.activeTitle {
					color: #366AFF;
				}
			}
			.self-skill {
				padding: 0px 10px;
				flex: auto;
                overflow-y: auto;
				height: 0px;
                // min-height: 250px;
				padding-bottom: 10px;
				.self-skill-cell {
					height: 38px;
					width: 100%;
					display: flex;
					justify-content: flex-start;
					border-bottom: solid 1px lightgrey;
					align-items: center;
					cursor: pointer;
					&.last{
						padding-bottom: 35px;
					}
					.order-number {
						text-align: center;
						height: 16px;
						// width: 16px;
						padding: 0 5px;
						background: #FFFFFF;
						border: 1px solid #366AFF;
						border-radius: 5px;
						line-height: 18px;
						background-color: #fff;
						color: #366AFF;
						margin-right: 35px;
						font-size: 12px;
						text-align: center;
					}
					.skill-cell-content {
						flex: 1;
						display: flex;
						justify-content: space-between;
						align-items: center;
						.skill-cell-content-name {
							flex: 1;
							overflow: hidden;
							text-align: left;
							.el-input {
								width: 100% !important;
							}
						}
						.skill-cell-handle {
							flex: none;
							width: 70px;
							color: #366AFF;
							font-size: 12px;
							text-align: right;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							.guoran-tongyichicun-16-09-shanchu2{
								margin: 0 10px;
							}
							.guoran-a-16-10{
								display: inline-block;
							}
							.guoran-tongyichicun-16-16-paixuhover{
								display: none;
							}
							.guoran-tongyichicun-16-16-paixuhover,.guoran-a-16-10{
								font-size: 17px;
							}
						}
						.skill-cell-handle-join {
							// display: none;
							flex: none;
							width: 120px;
						}
					}
					&.disabled{
						&:hover{
							.add-my-know{
								color: #FFF;
								background-color: #8AA6F8;
								border-color: #8AA6F8;
								border-radius: 13px;
							}
						}
						
					}
					&:hover{
						background-color: #F6F8FD;
						.order-number {
							background-color: #366AFF;
							color: #fff;
						}
						.guoran-a-16-10{
							display: none !important;
						}
						.guoran-tongyichicun-16-16-paixuhover{
							display: inline-block !important;
							color:#366AFF ;
						}
						.add-my-know{
							width: 118px;
							height: 26px;
							background: #366AFF;
							border-radius: 13px;
							color: #fff;
						}
           		 	}
				}
			}
			.add-btn{
				flex: none;
				bottom: 0;
				width: 336px;
				height: 47px;
				background: #FFFFFF;
				border-top: 1px solid #E0E6F7;
				border-radius: 0px 0px 5px 5px;
				font-size: 14px;
				color: #366AFF;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.el-icon-plus{

				}
			}
		}
		.outer-auth {
			width: 338px;
			// max-height: 450px;
			background: #FBFCFD;
			border: 1px solid #E0E6F7;
			border-radius: 5px;
			padding-top: 15px;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			// margin-bottom: 50px;
			.title-box {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: none;
				height: 45px;
				.title {
					font-weight: 700;
					height: 28px;
					cursor: pointer;
					font-size: 14px;
					font-weight: bold;
					color: #000000;
				}
				.activeTitle {
					color: #366AFF;
				}
			}
			.self-skill {
				padding: 0px 10px;
				flex: auto;
				height: 0px;
                overflow-y: scroll;
                // min-height: 250px;
				padding-bottom: 10px;
				.self-skill-cell {
					min-height: 38px;
					width: 100%;
					display: flex;
					justify-content: flex-start;
					border-bottom: solid 1px lightgrey;
					align-items: center;
					cursor: pointer;
					&.last{
						padding-bottom: 35px;
					}
					.order-number {
						text-align: center;
						height: 16px;
						width: 16px;
						background: #FFFFFF;
						border: 1px solid #366AFF;
						border-radius: 5px;
						line-height: 18px;
						background-color: #fff;
						color: #366AFF;
						margin-right: 35px;
						font-size: 12px;
						text-align: center;
					}
					.skill-cell-content {
						flex: 1;
						display: flex;
						justify-content: space-between;
						align-items: center;
						.skill-cell-content-name {
							flex: 1;
							overflow: hidden;
							text-align: left;
							&.template{
								display: -webkit-box;
								/*设置为弹性盒子*/
								-webkit-line-clamp: 1;
								/*最多显示3行*/
								overflow: hidden;
								/*超出隐藏*/
								text-overflow: ellipsis;
								/*超出显示为省略号*/
								-webkit-box-orient: vertical;
								word-break: break-all;

							}
							.el-input {
								width: 100% !important;
							}
						}
						.skill-cell-handle {
							flex: none;
							width: 70px;
							color: #366AFF;
							font-size: 12px;
							text-align: right;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							.guoran-tongyichicun-16-09-shanchu2{
								margin: 0 10px;
							}
							.guoran-a-16-10{
								display: inline-block;
							}
							.guoran-tongyichicun-16-16-paixuhover{
								display: none;
							}
							.guoran-tongyichicun-16-16-paixuhover,.guoran-a-16-10{
								font-size: 17px;
							}
						}
						.skill-cell-handle-join {
							// display: none;
							flex: none;
							width: 120px;
						}
					}
					&.disabled{
						&:hover{
							.add-my-know{
								color: #FFF;
								background-color: #8AA6F8;
								border-color: #8AA6F8;
								border-radius: 13px;
							}
						}
						
					}
					&:hover{
						background-color: #F6F8FD;
						.order-number {
							background-color: #366AFF;
							color: #fff;
						}
						.guoran-a-16-10{
							display: none !important;
						}
						.guoran-tongyichicun-16-16-paixuhover{
							display: inline-block !important;
							color:#366AFF ;
						}
						.add-my-know{
							width: 118px;
							height: 26px;
							background: #366AFF;
							border-radius: 13px;
							color: #fff;
						}
           		 	}
				}
			}

		}
		
		.outer-auth {
			margin-left: 20px;
			padding: 0;
			// height: 535px;
			border: 1px solid #E0E6F7;
			.title-box{
				display: flex;
				align-items: center;
				// margin-top: -1px;
				.title{
					width: 169px;
					height: 44px;
					background: #FFFFFF;
					border-bottom: 1px solid #E0E6F7;
					text-align: center;
					//line-height: 44px;
					font-size: 14px;
					color: #A9B3C6;
					cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
					&.activeTitle{
						background-color: #FBFCFD;
						color: #000000;
						font-weight: bold;
						border-right: 1px solid #E0E6F7;
						border-left: 1px solid #E0E6F7;
						border-bottom: none;
					}
				}
				.guan{
					&.activeTitle{
						border-left: none;
					}
					
				}
				.my{
					&.activeTitle{
						border-right: none;
					}
				}
				.self-skill{
					// height: 390px;
				}
			}
			.add-my-know{
				font-size: 12px;
				// font-family: Microsoft YaHei;
				font-weight: 400;
				color: #366AFF;
				line-height: 26px;
				width: 118px;
				height: 26px;
				text-align: center;
			}
		}
		
	}
</style>