import { render, staticRenderFns } from "./Rich_text_pro_v2.vue?vue&type=template&id=fd0fd1dc"
import script from "./Rich_text_pro_v2.vue?vue&type=script&lang=js"
export * from "./Rich_text_pro_v2.vue?vue&type=script&lang=js"
import style0 from "./Rich_text_pro_v2.vue?vue&type=style&index=0&id=fd0fd1dc&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports