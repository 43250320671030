<template>
    <div class="answer-video">
        <div class="respose-video">
            <div class="album albumvideo">
                <div>
                    <div class="pic_img">
                        <div class="pic_img_box">
                            <el-upload class="upload-ask" :show-file-list="false" :http-request="((data) => {
                                return aliyunOssRequest(data, true)
                            })" :before-upload="(file) => beforeUploadVideo(file, index)" :on-exceed="uploadExceed"
                                :on-success="(res, file) =>
                                        handleVideoSuccess(res, file, index)
                                    " :on-remove="handleRemove" :auto-upload="true"
                                accept=".mp4,.MP4,.ogg,.OGG,.avi,.AVI,.mov,.MOV,.flv,.FLV,.wmv,.WMV" multiple>
                                <video width="248px" height="148px" controlslist="nodownload" object-fit="fill" v-if="unit.content.url != '' &&
                                    !unit.content.videoFlag
                                    " v-bind:src="unit.content.url" muted="true" class="avatar video-avatar"
                                    controls="controls">
                                    {{$t('flowComponent.noSupportVideo')}}
                                </video>
                                <i v-else-if="unit.content.url == '' &&
                                    !unit.content.videoFlag
                                    " class="el-icon-plus avatar-uploader-icon"></i>
                                <el-progress v-if="unit.content.videoFlag == true" type="circle" :percentage="unit.content.videoUploadPercent
                                    " style="margin-top: 7px"></el-progress>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <p class="Upload_pictures">
                    <span></span>
                    <span>{{$t('flowComponent.uploadVideoTip')}}</span>
                </p>
            </div>
        </div>
        <!-- <div class="ask-self-switch-outer-bg">
            <div class="ask-self-switch-outer">
                <div class="ask-self-switch">
                    <span class="ask-self-switch-pre">禁止客户端下载视频</span>
                </div>
                <div class="icon-switch-box-true" v-if="unit.content.nodownload" @click.stop="
                    changeStatus(false)
                    ">
                    <i class="el-icon-check"></i>
                    <div></div>
                </div>
                <div class="icon-switch-box-false false" v-if="!unit.content.nodownload" @click.stop="
                    changeStatus(true)
                    ">
                    <div></div>
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { ossConfig, multipartUpload, ossFileUrl } from "./js/AliyunIssUtilWithProcess";
export default {
    props: ["unit"],
    data() {
        return {
            importFileUrl: location.origin + "/api/oss",
        };
    },
    methods: {
        aliyunOssRequest(data, ishandlerUpload) {
            let file = data.file;
            let res = multipartUpload(ossConfig, file, (process) => {
                // console.log(168, process, file);
                this.unit.content.videoFlag = true;
                this.unit.content.videoUploadPercent =
                    (process * 100).toFixed(0);
            });
            console.log(76, res);
            if (ishandlerUpload) {
                res.then(response => {
                    console.log(response);
                    if (response.res.status == 200) {
                        this.unit.content.isShowUploadVideo = true;
                        this.unit.content.videoFlag = false;
                        this.unit.content.videoUploadPercent = 0;
                        if (response.nameType == 'PRIVATE') {
                            this.unit.content.url = response.url;
                            console.log(this.unit.content.url);
                        } else {
                            this.unit.content.url = ossFileUrl(ossConfig, response.name);
                        }
                    } else {
                        this.$message.error("上传失败，请重新上传");
                    }
                })
            }
            // console.debug('oss upload res', data, res);
            return res;
        },
        //上传前回调
        beforeUploadVideo(file, index) {
            // console.log("process.env.VUE_APP_UPLOAD_TYPE:", process.env.VUE_APP_UPLOAD_TYPE);
            // console.log(file, index);
            this.activeVideoIndex = index;
            var fileSize = file.size / 1024 / 1024 < 500;
            if (
                [
                    "video/mp4",
                    "video/ogg",
                    "video/flv",
                    "video/avi",
                    "video/wmv",
                    "video/rmvb",
                    "video/mov",
                    "video/quicktime",
                ].indexOf(file.type) == -1
            ) {
                this.$message.error("请上传正确的视频格式");
                return false;
            }
            if (!fileSize) {
                this.$message.error("视频大小不能超过500MB");
                return false;
            }
            this.isShowUploadVideo = false;
        },
        //进度条
        uploadVideoProcess(event, file, fileList, activeVideoIndex) {
            this.unit.content.videoFlag = true;
            this.unit.content.videoUploadPercent =
                file.percentage.toFixed(0) - 0;
            console.log(file.percentage.toFixed(0) - 0);
            // this.videoFlag = true;
            // this.videoUploadPercent = file.percentage.toFixed(0) * 1;
        },
        //上传成功回调
        handleVideoSuccess(res, file, activeVideoIndex) {
            console.log(135, res, file);
            // console.log(res, file);
            // this.isShowUploadVideo = true;
            this.unit.content.isShowUploadVideo = true;
            this.unit.content.videoFlag = false;
            this.unit.content.videoUploadPercent = 0;
            // this.videoFlag = false;
            // this.videoUploadPercent = 0;

            //前台上传地址
            // if (file.status == "success") {
            //     // this.videoForm.showVideoPath = file.url;
            //     this.unit.content.url = ossFileUrl(ossConfig, res.name);
            // } else {
            //     this.$message.error("上传失败，请重新上传");
            // }

            //后台上传地址
            // if (res.code == 0) {
            //     // this.videoForm.showVideoPath = res.data;
            //     this.unit.content.url = ossFileUrl(ossConfig, res.name);
            // } else {
            //     this.$message.error(res.message);
            // }
        },
        changeStatus(status) {
            this.unit.content.nodownload = status;
        },
    },
};
</script>
<style lang="less" scoped>
.answer-video {
    margin-bottom: 16px;

    .ask-self-switch-outer-bg{
        margin-top: 8px;
        color: #616161;
        background-color: #fbfcfd;
        margin-bottom: -10px;
    }

    .respose-video {
        display: flex;
        justify-content: space-around;

        .video-avatar {
            border-radius: 5px;
        }

        .albumvideo {
            .pic_img {
                margin-bottom: 10px;
                margin: 0 auto;
                height: 148px;
                width: 248px;

                /deep/.el-upload {
                    height: 148px;
                    width: 248px;
                    background: #fafbff;
                    border: 1px solid #e0e6f7;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 148px;
                    font-size: 22px;
                    color: #87a9ff;
                }

                .el-upload:hover {
                    border: 1px dashed skyblue;
                }

                /deep/.el-icon-plus {
                    margin-top: 60px;
                    font-size: 28px;
                    color: #87a9ff;
                }
            }
        }

        .Upload_pictures {
            margin-top: 18px;
            color: #a9b3c6;
            font-size: 13px;
        }
    }
}
</style>