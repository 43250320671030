<template>
    <div class="config-header">
        <div class="header-wrapper">
            <div
                style="text-align: left;padding: 0 15px"
                v-if="isPart == 1 || isBotLink"
                class="interaction-name"
            >
                {{ activeNodeName }}
            </div>
            <div style="text-align: right; width: 100%;padding: 0 15px" v-if="isPart == 1">
                <span style="color: #e6a23c"
                    >{{$t('flowComponent.saveTip1')}}</span
                >
                <el-button
                    size="small"
                    slot="reference"
                    plain
                    @click="closePartDrewer"
                    >{{$t('common.close')}}</el-button
                >
            </div>
            <el-input
                v-if="isPart == 0 && !isBotLink"
                type="text"
                ref="elInputName"
                v-model="activeNodeName"
                spellcheck="false"
                class="interaction-name"
                prefix-icon="el-icon-edit"
                maxlength="40"
            />
            <div v-if="isPart == 0" class="interaction-save">
                <el-popover
                    placement="top"
                    width="160"
                    trigger="manual"
                    v-model="cancelSaveVisible"
                >
                    <p>{{$t('flowComponent.closeTip1')}}</p>
                    <div style="text-align: right; margin: 0; padding: 10px">
                        <el-button
                            size="mini"
                            type="text"
                            @click="changeCancelSaveVisible"
                            >{{$t('common.cancel')}}</el-button
                        >
                        <el-button
                            type="primary"
                            size="mini"
                            @click="cancelSaveConfirm"
                            >{{$t('common.confirm')}}</el-button
                        >
                    </div>
                    <el-button
                        size="small"
                        slot="reference"
                        plain
                        @click="checkActionListChanged"
                        >{{$t('common.close')}}</el-button
                    >
                </el-popover>

                <el-button
                    @click="saveChatFlow"
                    size="small"
                    v-if="
                        !(
                            (nodeId == 'start' &&
                                !RP_Visible('OPEN_PROCESS_START')) ||
                            (nodeId == 'end' &&
                                !RP_Visible('OPEN_PROCESS_END')) ||
                            (!isStartOrEnd &&
                                !RP_Visible('OPEN_PROCESS_OTHER') &&
                                isOtherSkill) ||
                            (!isStartOrEnd &&
                                !isOtherSkill &&
                                !RP_Visible('OPEN_PROCESS_CUSTOMIZE'))
                        )
                    "
                    type="primary"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </div>
        <div
            v-show="
                (nodeId == 'start' && !RP_Visible('OPEN_PROCESS_START')) ||
                (nodeId == 'end' && !RP_Visible('OPEN_PROCESS_END')) ||
                (!isStartOrEnd &&
                    !RP_Visible('OPEN_PROCESS_OTHER') &&
                    isOtherSkill) ||
                (!isStartOrEnd &&
                    !isOtherSkill &&
                    !RP_Visible('OPEN_PROCESS_CUSTOMIZE'))
            "
            class="node-rp-tips"
        >
            <span class="el-icon-warning"></span>
            <span>{{$t('flowComponent.noEditTip')}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "isPart",
        "nodeId",
        "intentId",
        "isStartOrEnd",
        "isOtherSkill",
        "nodeTree",
        "activeNodeName",
        "cancelSaveVisible",
        "isBotLink"
    ],
    data() {
        return {};
    },
    mounted(){
        this.$nextTick(() => {
            if(!this.isBotLink){
                this.$refs.elInputName.blur();
            }   
        })
    },
    methods: {
        closePartDrewer() {
            this.$emit("closePartDrewer");
        },
        cancelSaveConfirm() {
            this.$emit("cancelSaveConfirm");
        },
        checkActionListChanged() {
            this.$emit("checkActionListChanged");
        },
        changeCancelSaveVisible(){
            this.$emit("changeCancelSaveVisible");
        },
        saveChatFlow() {
            // 编辑节点名称
            if (this.activeNodeName.trim().length === 0) {
                this.$message.error("节点名称不能为空！");
            } else {
                console.log(143, this.nodeId)
                if (this.nodeId == 'start' || this.nodeId == 'end') {
                    this.$emit("saveChatFlow");
                    this.nodeTree.forEach((skillItem, skillIndex) => {
                        skillItem.children.forEach(
                            (classItem, classIndex) => {
                                classItem.children.forEach(
                                    (intentItem, intentIndex) => {
                                        if (
                                            intentItem.id === this.intentId
                                        ) {
                                            this.$emit(
                                                "changeActiveNodeName",
                                                this.activeNodeName
                                            );
                                            this.$emit(
                                                "closeIntentNodeList",
                                                skillIndex,
                                                classIndex,
                                                intentIndex,
                                                true
                                            );
                                        }
                                    }
                                );
                            }
                        );
                    });
                } else {
                    this.FetchPut(
                        this.requestUrl.chatFlowNode.updateNodeName,
                        this.nodeId,
                        {
                            intentId: this.intentId,
                            name: this.activeNodeName.trim(),
                        }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.$emit("saveChatFlow");
                            this.nodeTree.forEach((skillItem, skillIndex) => {
                                skillItem.children.forEach(
                                    (classItem, classIndex) => {
                                        classItem.children.forEach(
                                            (intentItem, intentIndex) => {
                                                if (
                                                    intentItem.id === this.intentId
                                                ) {
                                                    this.$emit(
                                                        "changeActiveNodeName",
                                                        this.activeNodeName
                                                    );
                                                    this.$emit(
                                                        "closeIntentNodeList",
                                                        skillIndex,
                                                        classIndex,
                                                        intentIndex,
                                                        true
                                                    );
                                                }
                                            }
                                        );
                                    }
                                );
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
.config-header {
    /deep/.el-button {
        border-radius: 16px;
    }
    .header-wrapper {
        flex: none;
        height: 55px;
        display: flex;
        border-bottom: solid 1px #f0f1f7;
        background-color: #f0f1f7;
        align-items: center;
        .interaction-name {
            padding-left: 8px;
            width: 100%;
            font-size: 14px;
            border: 0;
            background: transparent;
        }
        /deep/.el-input__inner {
            border: none;
            flex-grow: 1;
            font-size: 14px;
            border: 0;
            background: transparent;
        }
        /deep/.el-input__prefix {
            position: absolute;
            left: 15px;
        }
        input::placeholder {
            color: #647384;
        }
        .interaction-save {
            display: flex;
            width: 300px;
            margin-right: 32px;
            justify-content: flex-end;
            .el-button {
                margin-left: 8px;
            }
        }
    }
    .node-rp-tips {
        line-height: 12px;
        height: 12px;
        color: red;
        text-align: right;
        margin-right: 48px;
        margin-bottom: 10px;
    }
}
</style>