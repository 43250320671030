<template>
    <div v-if="list.length > 0 && showBotKeywordRecommend" :class="['bot-keyword-recommend',isFixed ? 'isFixed' : '',type==='entity'?'entity':'']" :style="{width:width+'px',left:left+'px',top:positionTop+'px'}" >
        <div class="bot-keyword-recommend-header-title">
            <span>{{$t('botIntent.botSettingNew.recommendedSynonyms')}}</span>
            <div class="bot-keyword-recommend-header-right">
                <div class="add-all" @click.stop="addAll"> <i class="iconfont guoran-a-18-35" ></i> {{$t('botIntent.botSettingNew.addAll')}}</div>
                <div class="iconfont guoran-shanchu" @click.stop="closeFn"></div>
            </div>
        </div>
        <div class="bot-keyword-recommend-content">
           <template v-if="list.length > 0">
                <div 
                    v-for="(item,index) in list" 
                    :key="item.id" 
                    :class="['bot-keyword-recommend-content-item',activeIndex === index ? 'active' : '']"
                    @click.stop="addToInput(item,index)">
                    <div class="left">
                        <div class="bot-keyword-recommend-content-order-num">{{index+1}}</div>
                        <span class="text one-column-ellipsis">{{item.keyword}}</span>
                    </div>
                    <span>
                        <i class="iconfont guoran-tongyichicun-hulve" @click.stop="ignore(item,index)"></i>
                        <i class="iconfont guoran-a-18-35" @click.stop="addToInput(item,index)"></i>
                    </span>
                </div>
           </template>
           <!-- <div v-else class="no-data">无近义词推荐~</div> -->
        </div>
    </div>
</template>

<script>
export default {
    props:{
        showBotKeywordRecommend:{
            type:Boolean,
            default:false
        },
        // 宽度
        width:{
            type:Number,
            default:340
        },
        // 定位 left
        left:{
            type:Number,
            default:0 
        },
        // 当前item
        dataObj:{
            type:Object,
            default(){
                return{}
            }
        },
        // 当前index
        dataIndex:{
            type:Number,
            default:0
        },
        positionTop:{
            type:Number,
            default:45 
        },
        keyword:{
            type:String,
            default:''
        },
        isFixed:{
            type:Boolean,
            default:false
        },
        type:{
            type:String,
            default:''
        },

    },
    data(){
        return {
            list:[],
            dataObjCopy:{},
            returnKeyWord:[],
            handelKeyWord:'',
            activeIndex:0,
            keyCodeNum:0
        }
    },
    watch:{
        showBotKeywordRecommend(n){
            if(n){
                this.list = [];
                this.dataObjCopy = JSON.parse(JSON.stringify(this.dataObj));
                this.handelSearch();
            }
        },
        keyword(n){
            if(this.showBotKeywordRecommend){
                this.handelSearch(); 
            }
        }
    },
    mounted(){
        

    },
    methods:{
        handlerKeyCode(){
            this.$nextTick(() => {
                 // 监测键盘事件  上下,回车
                window.onkeydown = (e) => {
                    // console.log(e.keyCode === 13,'关键词推荐-------回车');
                    if(this.showBotKeywordRecommend){
                        if (e.keyCode === 38) {
                            // 上
                            if(this.activeIndex === 0){
                                this.activeIndex = 0;
                            } else {
                                this.activeIndex -= 1;
                            }
                        } else if (e.keyCode === 40) {
                            // 下
                            if(this.activeIndex+1 === this.list.length){
                                this.activeIndex = 0;
                            } else {
                                this.activeIndex += 1;
                            }
                        } else if (e.keyCode === 13){
                            // 回车
                        //    setTimeout(() => {
                            this.addToInput(this.list[this.activeIndex])
                        //    }, 300);
                        }
                    }
                }
            })
        },
        handelSearch(){
            // console.log(this.keyword,'this.keyword');
            if(typeof this.keyword === 'string'){
                this.handelKeyWord = this.keyword.replace(/，/gi, ",");
            } else {
                this.handelKeyWord = this.keyword.join(',');
            }
            let _keyword = this.handelKeyWord.replace(/,/gi, "");
            if(_keyword.length === 0){
                return false
            }
            //  console.log(this.handelKeyWord,'handelKeyWord----------');
            if(this.handelKeyWord[this.handelKeyWord.length-1] === ',' || this.handelKeyWord[this.handelKeyWord.length-1] === '，'){
                this.handelKeyWord = this.handelKeyWord.slice(0,this.handelKeyWord.length-1);
            }
            // console.log(this.handelKeyWord,'handelKeyWord');

            this.activeIndex = 0;
            if(_keyword.length > 0 && _keyword[0] !== '' && _keyword !== ''){
               let url = this.requestUrl.recommend.keyword + "?keyword=" + encodeURIComponent(this.keyword)
                this.FetchGet(url).then((res) => {
                    if (res.code === "0") {
                        if(res.data.length === 0){
                            this.$emit('noBotKeywordRecommend')
                        } else {
                            this.handlerKeyCode();
                            this.$emit('showBotKeywordRecommend')
                        }
                        this.list = res.data || [];
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$emit('closeBotKeywordRecommend')
            }
        },
        // 忽略
        ignore(item,index){
            // console.log(item);
            this.FetchPut(this.requestUrl.recommend.blacklist ,item.id).then((res) => {
                if (res.code === "0") {
                    this.list.splice(index,1);
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        // 单个添加
        addToInput(item,index){
            this.$nextTick(() => {
                // console.log('单个添加');
                let str = '';
                let arr = this.handelKeyWord.split(',');
                arr.length > 0&&arr.forEach((v,i) => {
                    str += v + '，';
                })
                str += item.keyword+ '，';
                if(this.type === 'rule' || this.type === 'super'){
                    // this.$emit('addToInput',item.keyword+ '，')
                    // console.log(item.keyword,'item.keyword');
                    this.$emit('addToInput',item.keyword)
                } else {
                    this.$emit('addToInput',str)
                }
            })
            
        },
        // 全部添加
        addAll(){
            this.$nextTick(() => {
                // console.log('全部添加');
                if(this.list.length === 0) return;
                let newStr='';
                 if(this.type === 'rule' || this.type === 'super'){

                 } else {
                    newStr +=this.handelKeyWord+'，';
                 }
                this.list.forEach((item,index) => {
                    newStr += item.keyword+'，';
                })
                console.log(newStr,'newStr');
                this.$emit('addToInput',newStr)
            })
        },
        // 关闭
        closeFn(){
            this.$emit('closeBotKeywordRecommend')
            // this.$emit('update:showBotKeywordRecommend',false)
        }
    }

}
</script>

<style scoped lang="less">
@import '../../../assets/less/main/common.less';
.bot-keyword-recommend{
    position: absolute;
    max-height: 281px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;
    top: 45px;
    z-index: 2;
    min-width: 260px;
    &.isFixed{
        position: fixed;
    }
    &.entity{
        position: static;
        background:transparent;
        box-shadow: none;
    }
    .bot-keyword-recommend-header-title{
        height: 47px;
        border-bottom: 1px solid #E0E6F7;
        display: flex;  
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        color: #000000;
        font-size: 16px;
        .bot-keyword-recommend-header-right{
            display: flex;  
            align-items: center;
           
            .add-all{
                width: 95px;
                height: 27px;
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                border-radius: 14px;
                line-height: 27px;
                text-align: center;
                margin-right: 10px;
                font-size: 14px;
                color: #366AFF;
                cursor: pointer;
                .iconfont{
                    font-size: 12px;
                    color: #366AFF;
                    margin-right: 3px;
                    font-weight: 600;
                }
            }
            .guoran-shanchu{
                width: 28px;
                height: 28px;
                background: #F5F7FB;
                border-radius: 50%;
                color: #616161;
                font-size: 16px;
                text-align: center;
                line-height: 28px;
                cursor: pointer;
            }
        }
        
    }
    .bot-keyword-recommend-content{
        max-height: 233px;
        overflow-x: hidden;
        overflow-y: auto;
        .bot-keyword-recommend-content-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 39px;
            border-bottom: 1px solid #E0E6F7;
            cursor: pointer;
            .left{
                display: flex;
                align-items: center;
            }
            .bot-keyword-recommend-content-order-num{
                width: 18px;
                height: 18px;
                background: #FFFFFF;
                border: 1px solid #366AFF;
                border-radius: 5px;
                text-align: center;
                line-height: 18px;
                margin-right: 8px;
                color: #366AFF;
                font-size: 12px;
                flex-shrink: 0;
            }
            .text{
                color: #616161;
                font-size: 14px;
                margin-right: 10px;
            }
            .iconfont{
               color:#366AFF;
               font-size: 16px;
               cursor: pointer;
               &.guoran-tongyichicun-hulve{
                   margin-right: 10px;
               }
            }
            &.active{
                background: #F5F8FF;
            }
        }
        .no-data{
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #a3adc6;
        }
    }
}
</style>